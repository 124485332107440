<template>
	<div class="search-content flex flex-col-center flex-wrap">
		<span class="search-title">产品名称</span>
		<el-input v-model="searchData.name" class="input-text" placeholder="请输入产品名称"></el-input>
		<span class="search-title">订单编号</span>
		<el-input v-model="searchData.num" class="input-text" placeholder="请输入订单编号"></el-input>
		<span class="search-title">下单时间</span>
		<el-date-picker v-model="timeText" type="datetimerange" prefix-icon="el-icon-date" range-separator="至" start-placeholder="开始时间" end-placeholder="结束时间" @change="changeTime"></el-date-picker>
		<el-button class="search-btn" type="primary" @click="searchClick">查询</el-button>
		<el-button class="rest-btn" @click="restClick">重置</el-button>
	</div>
</template>

<script>
	import {timeFormat} from "@/utils/index.js"
	export default {
		name: 'search-item',
		data(){
			return {
				timeText: '',
				searchData: {name: '', num: '', start: '', end: ''},//搜索数据
			}
		},
		created() {
			
		},
		methods: {
			//查询
			searchClick(){
				this.$emit('searchEvent', this.searchData);
			},
			//重置
			restClick() {
				this.searchData.name = '';
				this.searchData.num = '';
				this.searchData.time = '';
				this.searchData.start = '';
				this.searchData.end = '';
				this.timeText = '';
			},
			changeTime(e){
				this.searchData.start = timeFormat(e[0], 'yyyy-mm-dd hh:MM:ss');
				this.searchData.end = timeFormat(e[1], 'yyyy-mm-dd hh:MM:ss');
			}
			
		},
	}
</script>

<style lang="scss" scoped>
	.search-content{padding: 5px 30px 20px 30px;
		.search-title{font-size: 12px;color: #21333F;margin-right: 12px;}
		.input-text{width: 210px;margin-right: 40px;}
		.search-btn{width: 70px;height: 34px;background: #3984F5;border-radius: 4px;padding: 0;margin: 0 10px;}
		.rest-btn{width: 70px;height: 34px;background: #EEF1F3;border-radius: 4px;padding: 0;color: #6C7880;}
	}
	::v-deep(.el-input__inner){height: 34px;line-height: 34px;}
	::v-deep(.el-date-picker){width: 234px;}
	::v-deep(.el-input__icon){height: 34px;line-height: 34px;}
</style>